<template>
    <div>
        <v-tour name="portfolioTour" :steps="portfolioSteps" :options="{ debug: true }" ref="add-to-portfolio-tour" :callbacks="tourCallbacks"></v-tour>
    </div>
</template>

<script>

// import { createInitialState } from '@/js/createInitialState.js';
import {symbolArray, spreadTitle} from '@/js/main';
import * as fb from "@/firebase";

export default {

mounted() {
        console.log("portfolio-input.js mounted() starting. this.namespace=", this.namespace);
    },
    props: ['namespace'],
    data: function(){
        return {
            last: null,
            ticksize: null,
            futuresTabIndex: null,
            tourCallbacks: {
                    onFinish: this.onTourFinish,
                    onStop: this.onTourFinish,
                    onSkip: this.onTourFinish
            },
            strategyAdded: false
        }
    },
    computed: {
            activeModuleName() {
                return this.$store.getters["activeModuleName"];
            },
            strategies(){
                return this.$parent.$parent.$parent.$refs.tabsBox.$refs[this.activeModuleName + '-portfolio-output'][0].strategies;
            },
            email() {
                return this.$store.state.user.email;
            },
            portfolioDirectory() {
                return this.$store.state.user.portfolioDirectory;
            },
            portfolioSteps() {
                let that = this;
                return  [          
                {
                        target: '#tour-start-' + this.namespace, // We're using document.querySelector() under the hood
                        header: {
                            title: 'Portfolio Tour',
                        },
                        content: `<p style="text-align: left">You may use the ←, → and ESC keys to navigate through the tour.</p>` +
                            (that.strategyAdded ? `<p style="text-align: left">Since there were no tickers, one has been added.</p>` : `` )
                        ,
                        params: {
                            enableScrolling: false
                        },
                    before: () => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'
                       // console.log("that.$parent.$parent.$parent.$refs.tabsBox", that.$parent.$parent.$parent.$refs.tabsBox);
                       // console.log(" that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output']",  that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output']);

                        console.log("that.strategies=", that.strategies);
                        if(that.strategies.length == 0){
                            that.addToPortfolio();
                        }
                    } 
                },
                {
                    target: '#portfolioBuySell-0',
                    content: `<p style="text-align: left">Add words.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }
                },
                {
                    target: '#portfolioNumberOfOpenPositions-0',
                    content: `<p style="text-align: left">Notice that the number of open trades, the number of closed trades, realized profit and unrealized profit are all zero. This because we
                        haven't added any trades to this ticker yet.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }
                },                
                {
                    target: '#tradesIcon-0',
                    content: `<p style="text-align: left">To toggle the trades panel, click the icon above.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }
                },
                {
                    target: '#portfolio-trades-openDate-selector',
                    content: `<p style="text-align: left">Click the box above to change the entry date.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: () => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'

                        console.log("that.$parent.$parent.$parent.$refs.tabsBox",  that.$parent.$parent.$parent.$refs.tabsBox);
                       // console.log("that.$parent.$parent.$parent.$refs.tabsBox", that.$parent.$parent.$parent.$refs.tabsBox);


                        console.log(" that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output']",  that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output']);

                        let item =  that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].strategies[0];
                        item.portfolio.detailsType = "trades";
                        console.log("item=", item);
                         that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].openTrades(item);
                    } 
                },
                {
                    target: '#portfolio-trades-openPrice-selector',
                    content: `<p style="text-align: left">It is not necessary to enter the remaining parameters of the trade. All values can be added or edited later. We will set an entry
                                price of `+ (that.last - that.tickSize) +` for the tour.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: () => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'
                        console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-trades-table'", 
                                that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-trades-table']);

                        console.log("symbolArray=", symbolArray);
                        that.tickSize = symbolArray.find(x => x.eod === "S").tickSize;
                        console.log("that.tickSize=", that.tickSize);

                        let item = that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].strategies[0];
                        console.log("item=", item);

                        that.last = item.last;

                        let newTrade = that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-trades-table'].newTrade;
                        newTrade.openPrice = that.last-that.tickSize;
                        console.log("newTrade=", newTrade);

                        return new Promise((resolve) => {
                            setTimeout(function () {
                                 resolve('foo');
                            }, 200);
                        });

                    } 
                },
                {
                    target: '#portfolio-trades-add-trade-button',
                    content: `<p style="text-align: left">Click here to add the trade.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }
                },
                {
                    target: '#portfolio-trades-edit-icon',
                    content: `<p style="text-align: left">Click here to edit the trade.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: (type) => {
                        if(type === "next"){
                        console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-trades-table'", 
                                that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-trades-table']);

                                that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-trades-table'].addTrade();
                        }
                                return new Promise((resolve) => {
                                    setTimeout(function () {
                                         resolve('foo');
                                    }, 100);
                                });
                    } 
                },
                {
                    target: '#portfolio-trades-duplicate-icon',
                    content: `<p style="text-align: left">If you want to use a current trade as a template for a new trade, click here to duplicate the trade.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    } 
                },
                {
                    target: '#portfolio-trades-delete-icon',
                    content: `<p style="text-align: left">Click here to delete the trade.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    } 
                },                
                {
                    target: '#portfolio-trades-select-chart-icon',
                    content: `<p style="text-align: left">If you want to see a chart, click here and select.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: (type) => {
                       // console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output']", that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output']);
                       if(type === "previous"){
                        let item = that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].strategies[0];
                        item.portfolio.detailsType = "trades";
                        console.log("item=", item);
                        that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].openTrades(item);
                       }
                    }  
                },
                {
                    target: '#portfolio-alarms-icon-0',
                    content: `<p style="text-align: left">If you would like to add some email alarms, click, here.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    
                },
                {
                    target: '#portfolio-alarms-ruleTarget-selector',
                    content: `<p style="text-align: left">Select what you want to compare.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: () => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'

                        console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output']", that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output']);

                        let item = that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].strategies[0];
                        item.portfolio.detailsType = "alarms";
                        console.log("item=", item);
                        that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].openAlarms(item);
                    } 
                },
                {
                    target: '#portfolio-alarms-ruleComparator-selector',
                    content: `<p style="text-align: left">Select the type of comparison.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }                    
                },
                {
                    target: '#portfolio-alarms-ruleValue',
                    content: `<p style="text-align: left">Select the value to compare to. We have set the value to be greater than the last price.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: () => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'
                            console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs[portfolio-alarms-table]._props.item.last=", 
                            that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-alarms-table']._props.item.last);

                            that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-alarms-table'].newAlarm.value = that.last+that.tickSize;
                    }                    
                },
                {
                    target: '#portfolio-alarms-add-alarm',
                    content: `<p style="text-align: left">Click here to add the alarm.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    } ,
                    before: (type) => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'
                            console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs[portfolio-alarms-table]=", 
                            that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-alarms-table']);

                            if(type === "previous"){
                            let newAlarm = that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-alarms-table'].newAlarm;
                            that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-alarms-table'].removeAlarm(newAlarm);
                            }
                    }                      
                },  
                {
                    target: '#portfolio-alarms-status-0',
                    content: `<p style="text-align: left">Notice the status is "not triggered". If the closing price goes above `+ (that.last+that.tickSize) + ` you should receive an email in the evening with a notification.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: (type) => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'
                            console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs[portfolio-alarms-table]=", 
                            that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-alarms-table']);

                            if(type === "next"){
                            let newAlarm = that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-alarms-table'].newAlarm;
                            that.$parent.$parent.$parent.$refs.tabsBox.$refs[that.activeModuleName + '-portfolio-output'][0].$refs['portfolio-alarms-table'].addAlarm(newAlarm);
                            }
                    }                    
                },    
                {
                    target: '#portfolio-alarms-edit-0',
                    content: `<p style="text-align: left">Click here to edit the alarm.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }                  
                }, 
                {
                    target: '#portfolio-alarms-delete-0',
                    content: `<p style="text-align: left">Click here to delete the alarm.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }                  
                },   
                {
                    target: '#portfolio-delete-icon-0',
                    content: `<p style="text-align: left">If you would like to delete this ticker and all of its trades and alarms, click here.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    
                },
                {
                    target: '#portfolio-delete-folder',
                    content: `<p style="text-align: left">If you would like to delete this folder and everything in it, click here.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    
                }, 
            ];
        }
    },
    methods: {
        tourClickHandler(activeModuleName){
                console.log("tourClickHandler() starting. this.namespace=", this.namespace, " activeModuleName=", activeModuleName);
                if(this.namespace === activeModuleName){
                    this.$tours['portfolioTour'].start();
                }
        },
        getTicker(form) {
                let invertDatesMultiplier = 1;
                let noLegSwapping = true;
                let includeUnitMoves = true;
                let ticker = spreadTitle(JSON.parse(JSON.stringify(form)), invertDatesMultiplier, noLegSwapping, this.symbols, includeUnitMoves);
                console.log("ticker=", ticker);
                return ticker;
            },
            addToPortfolio() {
                console.log("addToPortfolio() starting. this.namespace=", this.namespace);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                console.log("generalForm=", generalForm);
                delete generalForm.browserSideOnly;
                delete generalForm.playback;
                delete generalForm.indicators;

             /*   console.log("this.open=", this.open);
                let openBar = values[this.open.replace(/-/g, "")];
                console.log("openBar=", openBar);

                let openPrice = typeof openBar !== 'undefined' ? openBar.close : null;
                console.log("openPrice=", openPrice);
                generalForm.open = this.open;
                generalForm.portfolio.openPrice = openPrice; */
                generalForm.portfolio.numberOfPositions = 1;
                generalForm.portfolio.folder = this.portfolioDirectory;
                generalForm.ticker = this.getTicker(generalForm, "old");
               
                console.log("generalForm=", generalForm);
                console.log("this.email=", this.email);

                fb.db.collection("portfolio-alpha")
                        .doc(this.email)
                        .collection("strategies")
                        .add(generalForm)
                        .then((docRef) => {
                            console.log("Document written with ID: ", docRef.id);
                            console.log('chart saved.');
                            this.result = "Success.";
                            this.strategyAdded = true;
                        });
            },
            onTourFinish(){
                console.log("onTourFinish() starting. this.namespace=", this.namespace);
                this.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar.sidebarClosed = true;
                this.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar.updateSelectedSidebarComponentToFirebase(null);
                this.strategyAdded = false;
            },
    }
};
</script>
