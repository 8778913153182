import { render, staticRenderFns } from "./portfolio-input.vue?vue&type=template&id=3ad07ee4&"
import script from "./portfolio-input.vue?vue&type=script&lang=js&"
export * from "./portfolio-input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports